@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "FuturaPT-Book";
    src: local("FuturaPT-Book"),
        url("./assets/fonts/FuturaPT-Book.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Bold";
    src: local("FuturaPT-Bold"),
        url("./assets/fonts/FuturaPT-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Demi";
    src: local("FuturaPT-Demi"),
        url("./assets/fonts/FuturaPT-Demi.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Light";
    src: local("FuturaPT-Light"),
        url("./assets/fonts/FuturaPT-Light.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "TenorSans";
    src: local("TenorSans-Regular"),
        url("./assets/fonts/TenorSans-Regular.ttf") format("truetype");
    font-weight: bold;
}

.addProductItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

body {
    margin: 0px;
}

h1 {
    margin: 0px;
}

.paypal-logo-color-white {
    display: none !important;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.Toastify {
    text-align: center;
}

.Toastify__toast-container {
    width: 50vw !important;
}

.h-half {
    height: 90vh;
}

.h-30vh {
    height: 30vh;
}

.h-40vh {
    height: 40vh;
}

.h-60vh {
    height: 60vh;
}

input {
    border: 1px solid black;
}

select {
    border: 1px solid black;
}

.h-60px {
    height: 60px;
}

.countdown-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 -8px 0 -8px;
    background: black;
    font-family: "FuturaPT-Bold"
}

.time-section {
    padding: 20px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white !important;
    font-family: "FuturaPT-Bold"
}

.time {
    margin: 0;
    font-size: 60px;
    font-weight: 800;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 480px) {
    .time {
        margin: 0;
        font-size: 40px !important;
        font-weight: 800 !important;
    }

    .countdown-wrapper {
        padding: 6vh;
        width: 102%;
    }
}
